import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ArticleListQuery } from '../../../graphql-types';
import ArticleList from '../../components/articles/list';
import { Article, ArticleListing, Category } from '../../components/articles/models';
import Layout from '../../components/layout';

export default function LearnPage() {
  const articleListData = useStaticQuery<ArticleListQuery>(listingQuery);
  const articleList = new ArticleListing();
  const categoryList: Category[] = [];

  for (let item of articleListData.allStrapiArticle.edges) {
    articleList.add(new Article(item.node));
  }

  for (let cat of articleListData.allStrapiCategory.edges) {
    categoryList.push(new Category(cat.node));
  }

  return (
    <Layout pageTitle="University | Latest Articles">
      <ArticleList list={articleList} categories={categoryList} />
    </Layout>
  );
}

const listingQuery = graphql`
  query ArticleList {
    allStrapiArticle(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          id
          author
          publishedAt
          publishedAtRel: publishedAt(fromNow: true)
          publishedAtFormatted: publishedAt(formatString: "MMMM DD, YYYY")
          childStrapiArticleBodyTextnode {
            childMdx {
              timeToRead
              excerpt(truncate: false, pruneLength: 200)
            }
          }
          banner {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 680)
              }
            }
            alternativeText
          }
          categories {
            id
            name
            slug
          }
        }
      }
    }
    allStrapiCategory {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
  }
`;
