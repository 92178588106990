import { motion } from 'framer-motion';
import React, { forwardRef, PropsWithChildren } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

type TitleMode = 'normal' | 'compact';

export interface VisualTitleProps {
  mode?: TitleMode;
  title?: string;
}

export const VisualTitle = forwardRef<
  HTMLHeadingElement,
  StylableProp<PropsWithChildren<VisualTitleProps>>
>((props, ref) => {
  return (
    <h1
      ref={ref}
      className={`${style.title} ${props.mode === 'compact' ? style.compact : ''} ${
        props.className
      }`}
      style={props.style}
    >
      {props.children ?? props.title}
    </h1>
  );
});

export const MotionVisualTitle = motion(VisualTitle);
export default VisualTitle;
