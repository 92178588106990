import { motion, useMotionValue } from 'framer-motion';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';
import { TabSelectorProps } from './models';

const SLIDER_OVERFLOW = 10;

export default function TabSelector(props: StylableProp<TabSelectorProps>) {
  const [selected, setSelected] = useState(props.selected ?? props.items[0].tabId);
  const selectedRef = useRef<HTMLLIElement[]>([]);
  const listRef = useRef<HTMLUListElement>(null);

  const sliderX = useMotionValue(0);
  const sliderWidth = useMotionValue(0);

  useLayoutEffect(() => {
    const listOffset = listRef.current?.offsetLeft || 0;

    for (const ref of selectedRef.current) {
      if (ref.classList.contains(style.selected)) {
        sliderWidth.set(ref.offsetWidth + SLIDER_OVERFLOW);
        sliderX.set(ref.offsetLeft - listOffset - SLIDER_OVERFLOW / 2);
      }
    }
  }, [selected]);

  return (
    <div className={`${style.tabSelector} ${props.className}`} style={props.style}>
      <ul ref={listRef} className={style.tabs + ' d-md-flex justify-content-around'}>
        {props.items.map((item, index) => (
          <li
            key={item.tabId}
            ref={(el) => (selectedRef.current[index] = el!)}
            className={selected === item.tabId ? style.selected : ''}
            onClick={() => {
              setSelected(item.tabId);
              props.onSelect(item.tabId);
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>

      <div className={style.sliderContainer}>
        <motion.div
          className={style.slider}
          style={{
            x: sliderX,
            width: sliderWidth
          }}
        ></motion.div>
      </div>
    </div>
  );
}
