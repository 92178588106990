import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { StylableProp } from '../../../utils/stylable-prop';
import RoundBtn from '../../round-btn';
import { SectionHeading } from '../../section-heading';
import { SectionSubheading } from '../../section-subheading';
import TabSelector from '../../tab-selector';
import VisualTitle from '../../visual-title';
import { Article, ArticleListing, Category } from '../models';
import * as style from './index.module.scss';

interface ArticleListProps {
  list: ArticleListing;
  categories: Category[];
}

interface ArticleItemProps {
  article: Article;
  index: number;
}

const ALL_CATS = new Category({ id: 'all-categories', slug: 'all', name: 'All Categories' });

export default function ArticleList(props: StylableProp<ArticleListProps>) {
  const [selectedCat, setSelectedCat] = useState(ALL_CATS);

  const adjustedList = props.list.articles
    .reduce<Article[]>(
      (list, article) => [...list, article],
      [...props.list.articles, ...props.list.articles]
    )
    .filter((article) => {
      if (selectedCat.id === ALL_CATS.id) {
        return true;
      } else {
        return article.categories.find((cat) => cat.id === selectedCat.id);
      }
    })
    .map((article, index) => {
      if (index < 2) {
        return <FeaturedArticle key={`${article.id}-${index}`} article={article} index={index} />;
      } else {
        return <RegularArticle key={`${article.id}-${index}`} article={article} index={index} />;
      }
    });

  const displayCategories = [ALL_CATS, ...props.categories];

  return (
    <section
      className={`${style.section} d-block d-md-flex flex-column align-items-center justify-content-center ${props.className}`}
    >
      <div className={style.wrapper + ' container d-flex flex-column justify-content-center'}>
        <VisualTitle className={style.sectionTitle + ' text-center mb-2'}>
          Free advice and coaching with Nuborrow University
        </VisualTitle>

        <div className={style.categorySelector + ' d-flex flex-column flex-md-row my-2 my-md-5'}>
          <div className={style.catSelectorPrefix + ' d-none d-md-block'}>Learn for free: </div>
          <TabSelector
            items={displayCategories.map((cat) => ({ tabId: cat.id, label: cat.name }))}
            style={{ flex: 1 }}
            onSelect={(selectedId) =>
              setSelectedCat(props.categories.find((cat) => cat.id === selectedId) ?? ALL_CATS)
            }
          />
        </div>

        <div className={style.featuredItems + ' d-flex my-4'}>
          {adjustedList[0]}
          {adjustedList[1]}
        </div>

        <div className={style.regularItems}>{adjustedList.slice(2).map((article) => article)}</div>
      </div>
    </section>
  );
}

const FeaturedArticle = ({ article, index }: ArticleItemProps) => {
  return (
    <div
      key={`${article.id}-${index}`}
      className={style.featuredArticle + ' d-flex flex-column mx-2'}
    >
      {article.bannerImg && (
        <Link to={`/learn/${article.slug}`}>
          <GatsbyImage
            className={`${style.banner}`}
            image={article.bannerImg!}
            alt={article.bannerAlt!}
            title={article.title!}
          />
        </Link>
      )}

      <header className="my-4 flex-grow-1">
        <SectionSubheading className="mb-0">
          What's new in {article.categories[0].name}
        </SectionSubheading>
        <SectionHeading className="mb-0">
          <Link to={`/learn/${article.slug}`}>{article.title}</Link>
        </SectionHeading>
      </header>

      <div className={style.meta + ' my-4'}>
        <RoundBtn isPrimary={true} href={`/learn/${article.slug}`}>
          Learn More
        </RoundBtn>
        <span className="published">{article.publishedAtFormatted}</span> &nbsp; | &nbsp;
        <span className="read-time">{article.timeToRead} min read</span>
      </div>
    </div>
  );
};

const RegularArticle = ({ article, index }: ArticleItemProps) => {
  return (
    <div key={`${article.id}-${index}`} className={style.regularArticle + ' d-flex my-5 mx-2'}>
      {article.bannerImg && (
        <Link to={`/learn/${article.slug}`}>
          <GatsbyImage
            className={`${style.banner}`}
            image={article.bannerImg!}
            alt={article.bannerAlt!}
            title={article.title!}
          />
        </Link>
      )}

      <div className={style.meta + ' px-1 py-2 d-flex flex-column align-items-start'}>
        <SectionSubheading compact={true} className="mb-0">
          {article.categories[0].name}
        </SectionSubheading>

        <SectionHeading compact={true} className={'flex-grow-1'}>
          <Link to={`/learn/${article.slug}`}>{article.title}</Link>
        </SectionHeading>

        <RoundBtn isPrimary={true} href={`/learn/${article.slug}`} className={style.smallBtn}>
          Learn More
        </RoundBtn>
      </div>
    </div>
  );
};
