import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { ArticleListQuery, ArticlePageQuery } from '../../../graphql-types';

export class Article {
  id: string;
  title: string;
  slug: string;
  author: string;
  publishedAt: Date;
  publishedAtRel: string;
  publishedAtFormatted: string;
  timeToRead: number;

  categories: Category[];
  related: RelatedArticle[];

  excerpt: string;
  content?: string;

  bannerAlt?: string;
  bannerCaption?: string;
  private banner?: ImageDataLike;

  get bannerImg() {
    return getImage(this.banner!);
  }

  constructor(private source: QueryArticle) {
    this.id = source.id;
    this.title = source.title!;
    this.slug = source.slug!;
    this.author = source.author!;
    this.publishedAt = new Date(source.publishedAt!);
    this.publishedAtRel = source.publishedAtRel;
    this.publishedAtFormatted = source.publishedAtFormatted;
    this.timeToRead = source.childStrapiArticleBodyTextnode?.childMdx?.timeToRead!;
    this.excerpt = source.childStrapiArticleBodyTextnode?.childMdx?.excerpt!;

    this.categories = source.categories!.map((cat) => new Category(cat as QueryCategory));

    if ((source as PageArticle).related) {
      this.related = (source as PageArticle).related!.map<RelatedArticle>((article) => ({
        id: article!.id,
        slug: article!.slug!,
        title: article!.title!,
        categories: article!.categories!.map((cat) => new Category(cat as QueryCategory))
      }));
    } else {
      this.related = [];
    }

    if ((source as PageArticle).childStrapiArticleBodyTextnode?.childMdx?.body) {
      this.content = (source as PageArticle).childStrapiArticleBodyTextnode?.childMdx?.body;
    }

    if (source.banner) {
      this.banner = source.banner.localFile as ImageDataLike;
      this.bannerAlt = source.banner.alternativeText ?? undefined;
      this.bannerCaption = (source as PageArticle)?.banner?.caption ?? undefined;
    }
  }
}

export class ArticleListing {
  articles: Article[];

  constructor() {
    this.articles = [];
  }

  add(article: Article) {
    this.articles.push(article);
  }
}

export class Category {
  id: string;
  slug: string;
  name: string;

  constructor(source: QueryCategory) {
    this.id = source.id;
    this.slug = source.slug!;
    this.name = source.name!;
  }

  toString() {
    return this.name;
  }
}

export interface RelatedArticle {
  id: string;
  title: string;
  slug: string;

  categories: Category[];
}

type PageArticle = NonNullable<ArticlePageQuery['article']>;
type ListingArticle = ArticleListQuery['allStrapiArticle']['edges'][0]['node'];

export type QueryArticle = PageArticle | ListingArticle;

export type QueryCategory = ArticleListQuery['allStrapiCategory']['edges'][0]['node'];
